// extracted by mini-css-extract-plugin
export var centerContain = "values-module--centerContain--4e430";
export var containBody = "values-module--containBody--63740";
export var containLeft = "values-module--containLeft--7f4f6";
export var containLinks = "values-module--containLinks--f87d6";
export var containLinksDescription = "values-module--containLinksDescription--211f4";
export var containLinksTitle = "values-module--containLinksTitle--18ea4";
export var containRight = "values-module--containRight--16a94";
export var containSection = "values-module--containSection--aa13b";
export var containTitle = "values-module--containTitle--42d71";
export var contieneImg = "values-module--contieneImg--79237";
export var contieneLista = "values-module--contieneLista--7cddb";
export var valuesSection = "values-module--valuesSection--4acb9";