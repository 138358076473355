// extracted by mini-css-extract-plugin
export var bodyContact = "contact-module--bodyContact--62f74";
export var btn = "contact-module--btn--0d034";
export var btnLottie = "contact-module--btnLottie--aee02";
export var contenerContact = "contact-module--contenerContact--114c6";
export var error = "contact-module--error--585a0";
export var fromContacto = "contact-module--fromContacto--72eed";
export var fromLeft = "contact-module--fromLeft--95a95";
export var fromRight = "contact-module--fromRight--3a35f";
export var textArea = "contact-module--textArea--852f0";
export var textBox = "contact-module--textBox--f9faa";