import React from 'react';
import Button from '../../UI/Button/button';
import * as styles from './header.module.scss';
import { useMediaQuery } from 'react-responsive';


import BannerHomePng from '../../../images/Team/working.png';
import BannerHomeWebp from '../../../images/Team/working.png';

import { useTranslation } from "gatsby-plugin-react-i18next"

const HeaderSection = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
	const bannerImage = isMobile ? BannerHomePng : BannerHomeWebp;

	const {t} = useTranslation();
	
	return (
		<section className={styles.headerSection}>
			<div className={styles.containHeader}>

				<div className={styles.infoHeader}>
					<p 
					data-sal-delay='300'
					data-sal='slide-up'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
						Tu proyecto desarrollado en la blockchain
					</p>
					<span
					data-sal-delay='300'
					data-sal='slide-up'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
						Equipos especializados para alcanzar tus metas tecnológicas
					</span>
					<div className={styles.doQuoteButton}>
						<Button linkTo='#contact' isLink type='black'>
						Cotiza tu proyecto
						</Button>
					</div>
				</div>
				<h1
				data-sal-delay='300'
				data-sal='slide-right'
				data-sal-duration='700'
				data-sal-easing='ease-out-back'>Blockchain</h1>

				<div className={styles.containImg}>
					<img 
						data-sal-delay='100'
						data-sal='zoom-in'
						data-sal-duration='200'
						data-sal-easing='ease-out-back'
						src={bannerImage} 
						alt='Banner de Home. Persona usando un dispisitivo monile'
						className={styles.logo}/>
				</div>

			</div>
		</section>
	);
};
export default HeaderSection;
