import React from "react";
import Layout from "../components/UI/layout";
import SEO from "../components/UI/seo";
import { graphql } from "gatsby";
import Header from "../components/Software/Header/header";
import Values from "../components/Software/Values/values";
import YourTeam from "../components/Software/YourTeam/team";
import Compani from "../components/Software/Compani/compani"
import Help from "../components/Contact/Help/help";
import Contact from "../components/Software/contact/contact";
import Blockchain from "../components/Software/Blockchain/blockchain";
import { useTranslation } from "react-i18next";




const Software = () => {
    const {t} = useTranslation();
    return(
      <Layout>
          <SEO title="Codeicus | tu equipo dedicado" />
          <Contact />
          <Header />
          <Blockchain />
          <Values />     
          <YourTeam />
          <Compani />
          <Help t={t}/>
      </Layout>
      
    )
  }
  
  export default Software


  export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;