import React from 'react';
import Button from '../../UI/Button/button';
import * as styles from './blockchain.module.scss';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from "gatsby-plugin-react-i18next"

const BlockchainSection = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

    const { t } = useTranslation();

    return (
        <section className={styles.headerSection}>
            <div className={styles.containHeader}>

                <div className={styles.infoHeader}>
                    <h1
                    data-sal-delay='300'
                    data-sal='slide-right'
                    data-sal-duration='700'
                    data-sal-easing='ease-out-back'>¿Por qué deberías tener tu proyecto blockchain?</h1>
                    <p
                        data-sal-delay='300'
                        data-sal='slide-up'
                        data-sal-duration='700'
                        data-sal-easing='ease-out-back'>
                        Los proyectos blockchain permiten:
                    </p>
                    <div className={styles.contieneLista}>
                        <ul>
                            <li>Mayor seguridad de la información</li>
                            <li>Mayor confiabilidad de los datos</li>
                            <li>Trazabilidad instantánea</li>
                            <li>Mayor eficiencia </li>
                            <li>Descentralización de la información</li>
                            <li>Mayor velocidad</li>
                        </ul>
                    </div>
                    <span
                        data-sal-delay='300'
                        data-sal='slide-up'
                        data-sal-duration='700'
                        data-sal-easing='ease-out-back'>
                        No pierdas la oportunidad de hacer crecer tu negocio.
                    </span>
                    <div>
                        <Button linkTo='#contact' isLink type='black'>
                            Ingresa a la blockchain
                        </Button>
                    </div>
                </div>
                

            </div>
        </section>
    );
};
export default BlockchainSection;
