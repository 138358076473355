// extracted by mini-css-extract-plugin
export var bera = "compani-module--bera--1a8d0";
export var companiSection = "compani-module--companiSection--3cff4";
export var containCompani = "compani-module--containCompani--e489b";
export var containImg = "compani-module--containImg--34d2a";
export var containLogo = "compani-module--containLogo--df3bb";
export var containLogoCompani = "compani-module--containLogoCompani--bf45e";
export var containTitle = "compani-module--containTitle--8abc2";
export var lenovo = "compani-module--lenovo--307d8";
export var lexus = "compani-module--lexus--e5ea5";
export var tcfa = "compani-module--tcfa--60c13";
export var wecover = "compani-module--wecover--8c5f2";